//=require ../../node_modules/owl.carousel/dist/owl.carousel.min.js
//=require ../../node_modules/popper.js/dist/umd/popper.min.js
//=require ../../node_modules/bootstrap/dist/js/bootstrap.min.js
//=require ../../node_modules/swiper/dist/js/swiper.min.js
//=require ../../node_modules/waypoints/lib/jquery.waypoints.min.js

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $.extend({
    ucwords : function(str) {
        var strVal = '';
        str = str.split(' ');
        for (var chr = 0; chr < str.length; chr++) {
            strVal += str[chr].substring(0, 1).toUpperCase() + str[chr].substring(1, str[chr].length) + ' ';
        }
        return strVal;
    }
  });



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Site = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        this.multiLevelMenu();
        this.dataToBg();
        this.navScrolled();
        this.burgerMenu();
        // this.ogGrid();
        this.contentGallery();
        this.osAnimation();
        this.swiperHome();
        this.swiperPhotos();
        this.swiperGallery();
        this.messagesClose();
        this.langMenu();
      },

      // -------------------------------------------------------------
      //   Multi Level Menu
      // -------------------------------------------------------------
      multiLevelMenu: function(){
        var el = $("#nav ul li a");
        var elActive = $("#nav > ul").find("a.active");

        elActive.parent("li").addClass("active");
        elActive.parent("li").parents("li").addClass("active-trail");

        if($("#nav ul ul").length) {
          $("#nav > ul").addClass("sublvl");
        }

        el.each(function() {
          if($(this).next("ul").length) {
            $(this).after('<span class="expand"></span>');
          }
        });

        $("#nav ul li .expand").on("click", function() {
          $(this).parent("li").toggleClass('open');
        });
      },


      // -------------------------------------------------------------
    	//   Data to BG
      //   <div data-bg="path/to/image.jpg"></div>
    	// -------------------------------------------------------------
    	dataToBg: function(){
    		$("*[data-bg]").each(function() {
    			var bg = $(this).data('bg');
  				if (bg.substr(0, 1) === '#') {
  					$(this).css({
  						'background-color': bg
  					});
  				}else{
  					$(this).css({
  						'background-image': 'url('+bg+')'
  					});
  				}
    		});
      },

      // -------------------------------------------------------------
      //   Fixed navigation
      // -------------------------------------------------------------
      navScrolled: function() {

        if ($(document).scrollTop() > 60) {
          $(".main-header").addClass("scrolled");
        } else {
          $(".main-header").removeClass("scrolled");
        }

      },
      // -------------------------------------------------------------
      //   Swiper home
      // -------------------------------------------------------------
      swiperHome:  function() {
        var mySwiper = new Swiper('.swiper-home', {
          slidesPerView: 1,
          spaceBetween: 0,
          speed: 1200,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false,
          },
        });
      },
      // -------------------------------------------------------------
      //   Swiper photos home
      // -------------------------------------------------------------
      swiperPhotos:  function() {
        var mySwiper = new Swiper('.swiper-photos', {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          centeredSlides: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      },
      // -------------------------------------------------------------
      //   Swiper gallery
      // -------------------------------------------------------------
      swiperGallery:  function() {
        var mySwiper = new Swiper('.swiper-gallery', {
          slidesPerView: 1,
          spaceBetween: 0,
          loop: true,
          centeredSlides: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        });
      },
      // -------------------------------------------------------------
      //   Burger menu
      // -------------------------------------------------------------
      burgerMenu: function() {
          $(document).on('click', ".trigger-menu", function(e) {
            e.preventDefault();
            $(this).toggleClass('open');
            $('body').toggleClass('open-menu');
          });
      },

      // ------------------------------------------------------------
      // og-grid
      // ------------------------------------------------------------
      ogGrid: function() {
        $('#og-grid .og-item a.og-toggle').on('click', function(e) {
          e.preventDefault();

          $('#og-grid .og-item').removeClass('og-expanded').removeAttr('style');
          $('#og-grid .og-expander').removeAttr('style');

          var $item = $(this).parent();

          var $expander = $item.find('.og-expander');
          var $exp_height = $expander.find('.og-expander-inner').outerHeight();

          var $timing = 500;
          var $href = $(this).attr('href');

          setTimeout(function () {
            $item.addClass('og-expanded');
            $item.css({
              height: ($item.height() + $exp_height + 20)
            });
            $expander.css({
              height: $exp_height
            });
            $('html, body').animate({scrollTop: $($href).offset().top - 150}, $timing);
          }, $timing);

          return false;
        });

        $('#og-grid .og-close').on('click', function(e) {
          $('#og-grid .og-item').removeClass('og-expanded').removeAttr('style');
          $('#og-grid .og-expander').removeAttr('style');
        });
      },

      // -------------------------------------------------------------
      //   content gallery
      // -------------------------------------------------------------
      contentGallery: function() {

        $('.content-galerie .owl-carousel').each(function() {
          $(this).owlCarousel({
            items:1,
            loop:true,
            nav:true,
            navText:['<i class="fa fa-angle-left" aria-hidden="true"></i>','<i class="fa fa-angle-right" aria-hidden="true"></i>'],
            autoplay:true,
          });
        });

      },
      // -------------------------------------------------------------
      //   on scroll animation
      // -------------------------------------------------------------
      osAnimation: function() {
        function onScrollInit( items, trigger ) {
          items.each( function() {
            var osElement = $(this),
              osAnimationClass = osElement.attr('data-os-animation'),
              osAnimationDelay = osElement.attr('data-os-animation-delay');

            osElement.css({
              '-webkit-animation-delay':  osAnimationDelay,
              '-moz-animation-delay':     osAnimationDelay,
              'animation-delay':          osAnimationDelay
            });

            var osTrigger = ( trigger ) ? trigger : osElement;

            osTrigger.waypoint(function() {
              osElement.addClass('animated').addClass(osAnimationClass);
            },{
              triggerOnce: true,
              offset: '90%'
            });
          });
        }

        onScrollInit( $('.os-animation') );
        $('.staggered-animation-container').each(function() {
          onScrollInit( $(this).find('.staggered-animation'), $(this) );
        });

      },

      // -------------------------------------------------------------
      //   close messages
      // -------------------------------------------------------------
      messagesClose: function() {

        $(".messages").prepend('<a href="#" class="close">×</a>');

        $(document).on("click", ".messages .close", function(e) {
          e.preventDefault();
          $(this).parents(".messages").slideUp(200);
        });

      },

      // -------------------------------------------------------------
      //   menu langues
      // -------------------------------------------------------------
      langMenu: function() {

        var container = $("ul.language-switcher-locale-url"),
            activeLang = container.find('li.active a').text();

        // create span with active lang
        container.parent().prepend('<span class="label">'+activeLang+'<i class="fa fa-caret-down" aria-hidden="true"></i></span>');
        // hide  active lang in ul
        container.find('li.active').remove();

        //show ul
        container.parent().find('.label').on('click', function (){
          container.slideToggle(300);
          $(this).find('.fa').toggleClass('fa-caret-down');
          $(this).find('.fa').toggleClass('fa-caret-up');
        });

        //hide ul
        $(document).on('click', function(e) {
          var $clicked = $(e.target);
          if (!$clicked.parents().hasClass("lang")) {
            container.slideUp(300);
            container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
            container.parent().find('.label').find('.fa').addClass('fa-caret-down');
          }
        });

        $(document).scroll(function() {
          if ($(document).scrollTop() >= 50) {
            container.slideUp(300);
            container.parent().find('.label').find('.fa').removeClass('fa-caret-up');
            container.parent().find('.label').find('.fa').addClass('fa-caret-down');
          }
        });

      },




      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },



    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },

      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },



    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Site;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).scroll( function(){
    // JavaScript to be fired on scroll
    UTIL.fire('common', 'navScrolled');
  });

})(jQuery); // Fully reference jQuery after this point.
